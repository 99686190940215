import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { ip } from "../login/LoginForm";

const Div = styled.div`
  word-break: keep-all;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
  }
  .rule {
    h2 {
      font-size: 20px;
      text-align: center;
      margin-bottom: 10px;
    }
    margin-bottom: 20px;
  }
  /* 미디어 쿼리: 화면 가로폭이 768px 미만일 때 */
  @media screen and (max-width: 768px) {
    .selectBox {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 0 auto;
      width: 85vw;
    }

    .input-name {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px auto;
    }
    input {
      font-size: 16px;
      line-height: 2;
      text-indent: 5px;
      width: 130px;
    }
    textarea {
      font-size: 14px;
    }
    select {
      font-size: 16px;
      margin-right: 10px;
      margin-bottom: 20px;
      height: 30px;
    }
    display: flex;
    flex-direction: row;
    table {
      margin: 0 auto;
      width: 100vw;
    }
    table,
    td,
    tr,
    th {
      border: 1px solid black;
      border-collapse: collapse;
      text-align: center;
    }
    td {
      height: 60px;
      font-size: 12px;
    }
    .box3 {
      width: 100%;
      height: 60px;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
    .box1,
    .box2 {
      height: 30px;
    }
    .has-data {
      background-color: orange; /* 데이터가 있는 경우 배경색 */
    }
    .no-data {
      background-color: aliceblue;
    }

    button {
      margin-left: 10px;
      font-size: 16px;
    }
  }

  /* 미디어 쿼리: 화면 가로폭이 768px 이상일 때 */
  @media screen and (min-width: 768px) {
     {
      margin-left: 50px;
    }

    input {
      font-size: 20px;
    }
    textarea {
      font-size: 20px;
    }
    .selectBox {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .input-name {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
    select {
      font-size: 20px;
    }
    display: flex;
    flex-direction: row;
    table {
      margin: 0 auto;
    }
    table,
    td,
    tr,
    th {
      border: 3px solid black;
      border-collapse: collapse;
      text-align: center;
    }
    td {
      width: 100px;
      height: 60px;
    }
    .box3 {
      width: 100px;
      height: 60px;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
    .box1,
    .box2 {
      width: 100px;
      height: 30px;
    }
    .has-data {
      background-color: orange; /* 데이터가 있는 경우 배경색 */
    }
    .no-data {
      background-color: aliceblue;
    }

    button {
      font-size: 20px;
    }
  }
`;
const Booking = () => {
  const [lessons, setLessons] = useState({});
  const [selectedRoom, setSelectedRoom] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [name, setName] = useState("");
  const [lessonDuration, setLessonDuration] = useState("");
  const [logs, setLogs] = useState([]);
  const handleRoomChange = (e) => {
    setSelectedRoom(e.target.value);
  };

  const handleTimeChange = (e) => {
    setSelectedTime(e.target.value);
  };

  const handleDurationChange = (e) => {
    setLessonDuration(parseInt(e.target.value, 10)); // 1시간 또는 2시간 설정
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedRoom || !selectedTime || !name || !lessonDuration) {
      alert("예약정보를 모두 입력해주세요.");
      return;
    }
    if (selectedRoom && selectedTime && name && lessonDuration) {
      const startIndex = times.indexOf(selectedTime);
      const selectedTimes = times.slice(
        startIndex,
        startIndex + lessonDuration
      );
      // 현재 이름으로 이미 예약된 횟수 확인
      const existingReservations = Object.values(lessons).filter(
        (lesson) => lesson.name === name
      );

      if (existingReservations.length >= 2) {
        alert("2시간 이상 예약이 불가능합니다.");
        return;
      }
      // 중복 체크
      const isDuplicate = selectedTimes.some(
        (time) => lessons[`${selectedRoom}-${time}`]
      );

      if (isDuplicate) {
        alert("이미 예약된 시간입니다.");
        return; // 중복된 예약이면 저장하지 않고 종료
      }
      const newLessons = selectedTimes.reduce((acc, time) => {
        acc[`${selectedRoom}-${time}`] = {
          name,
          type: lessonDuration === 1 ? "1시간" : "2시간",
        };
        return acc;
      }, {});

      try {
        // 2시간일 경우, 두 개의 시간을 DB에 저장
        for (const time of selectedTimes) {
          await axios.post(`https://${ip}/api/book`, {
            room: selectedRoom,
            time: time,
            name: name,
            type: lessonDuration === 1 ? "1시간" : "2시간",
          });
        }

        setLessons((prevLessons) => ({
          ...prevLessons,
          ...newLessons,
        }));
      } catch (err) {
        console.error(err);
      }
    }
  };
  const getLogs = async () => {
    try {
      const res = await axios.get(`https://${ip}/api/logs`);
      setLogs(res.data);
    } catch (err) {
      console.error(err);
    }
  };
  const handleDataDoubleClick = async (name, room, time) => {
    const check = confirm("정말 예약을 삭제하시겠습니까?");
    if (check) {
      const key = `${room}-${time}`;
      const updatedLessons = { ...lessons };
      if (updatedLessons[key]) {
        try {
          await axios.post(`https://${ip}/api/log`, {
            name: name,
            room: room,
            time: time,
          });
          getLogs();
          await axios.delete(`https://${ip}/api/book`, {
            data: { room, time },
          });
          delete updatedLessons[key];
          setLessons(updatedLessons);
        } catch (err) {
          console.error(err);
        }
      }
    } else {
      return;
    }
  };

  const renderCell = (room, time) => {
    const key = `${room}-${time}`;
    const lesson = lessons[key];

    const tdClass = lesson ? "has-data" : "no-data";

    return (
      <td
        key={key}
        className={tdClass}
        onDoubleClick={() => handleDataDoubleClick(lesson?.name, room, time)}
      >
        {lesson?.name || ""}
      </td>
    );
  };

  const times = [
    "오전 9시",
    "오전 10시",
    "오전 11시",
    "오후 12시",
    "오후 1시",
    "오후 2시",
    "오후 3시",
    "오후 4시",
    "오후 5시",
    "오후 6시",
    "오후 7시",
    "오후 8시",
    "오후 9시",
  ];

  const rooms = ["Room1", "Room2", "Room3", "Room4", "Room5", "Room6"];
  useEffect(() => {
    const fetchLessons = async () => {
      try {
        const res = await axios.get(`https://${ip}/api/book`);
        const lessonsData = res.data.reduce((acc, lesson) => {
          acc[`${lesson.room}-${lesson.time}`] = {
            name: lesson.name,
            type: lesson.type,
          };
          return acc;
        }, {});
        setLessons(lessonsData);
      } catch (err) {
        console.error(err);
      }
    };

    fetchLessons();
  }, []);
  useEffect(() => {
    getLogs();
  }, []);
  return (
    <Div>
      <div className="container">
        <div className="rule">
          <h2>연습실 사용 규칙</h2>
          <p>- 연습실 예약은 현장 방문 시에만 가능합니다.</p>
          <p>- 2시간 이상 예약 불가 </p>
          <p> &nbsp;&nbsp;(단, 공실이 많을 시 유동적으로 사용 가능)</p>
          <p>- 스튜디오 내 취식은 금지 입니다.</p>
          <p>- 연습실은 큐알코드로 꼭 예약하고 사용해주세요.</p>
          <p>- 연습실 사용 후 스피커를 꼭 꺼주세요.</p>
          <p>- 연습 후 전기를 꺼주시고, 문을 꼭 닫아주세요.</p>
          <p>- 로비에서 30분 이상 잡담을 하지 마세요.</p>
          <p> &nbsp;&nbsp;(다른 분들에게 피해가 될 수 있습니다.)</p>
          <p> - 서로 악기를 알려주는 행위는 금지 입니다. </p>
          <p>- 서로 악기 실력을 판단하지 마세요.</p>
        </div>
        <div className="book">
          <form onSubmit={handleSubmit}>
            <div className="selectBox">
              <select value={selectedRoom} onChange={handleRoomChange}>
                <option value="">방 선택</option>
                {rooms.map((room) => (
                  <option key={room} value={room}>
                    {room}
                  </option>
                ))}
              </select>
              <select value={selectedTime} onChange={handleTimeChange}>
                <option value="">시작 시간 선택</option>
                {times.map((time) => (
                  <option key={time} value={time}>
                    {time}
                  </option>
                ))}
              </select>
              <select value={lessonDuration} onChange={handleDurationChange}>
                <option value="">연습 시간 선택</option>
                <option value={1}>1시간</option>
                <option value={2}>2시간</option>
              </select>
            </div>
            <div className="input-name">
              <input
                type="text"
                name="name"
                value={name}
                onChange={handleNameChange}
                placeholder="이름 입력"
              />
              <button type="submit">예약하기</button>
            </div>
          </form>

          <table>
            <thead>
              <tr>
                <td>시간</td>
                {rooms.map((room) => (
                  <td key={room}>{room}</td>
                ))}
              </tr>
            </thead>
            <tbody>
              {times.map((time) => (
                <tr key={time}>
                  <td>{time}</td>
                  {rooms.map((room) => renderCell(room, time))}
                </tr>
              ))}
            </tbody>
          </table>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {logs.length > 0 &&
              logs.map((item) => {
                return (
                  <p
                    className="log"
                    style={{
                      marginBottom: 2,
                      marginTop: 2,
                      backgroundColor: "#ccc",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    {item.name}님이 {item.room} {item.time}예약을
                    <br />
                    {new Date(item.deleteTime).toLocaleString("ko-KR", {
                      timeZone: "Asia/Seoul",
                    })}
                    에 삭제하셨습니다.
                  </p>
                );
              })}
          </div>
        </div>
      </div>
    </Div>
  );
};

export default Booking;
