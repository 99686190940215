import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import styled from "styled-components";
import Modal from "../common/Modal";
import { Link } from "react-router-dom";
import { ip } from "../login/LoginForm";

const Div = styled.div`
  /* 미디어 쿼리: 화면 가로폭이 768px 미만일 때 */
  @media screen and (max-width: 768px) {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    form {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      input {
        width: 200px;
        line-height: 1.5;
        outline: none;
        margin: 5px auto;
      }
    }
    #tableBox {
      display: flex;
      flex-direction: column;
    }
    #member {
      table,
      td,
      tr {
        border-collapse: collapse;
        border: 1px solid #ccc;
        text-align: center;
        font-size: 12px;
      }
      td {
        height: 30px;
      }
    }
    h2 {
      margin: 2rem 0;
    }
    #today-lesson {
      h3 {
        margin: 30px auto;
      }
    }
  }

  /* 미디어 쿼리: 화면 가로폭이 768px 이상일 때 */
  @media screen and (min-width: 768px) {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    h2 {
      margin: 2rem 0;
    }
    #member {
      table,
      td,
      tr {
        border-collapse: collapse;
        border: 1px solid #ccc;
        text-align: center;
        width: 900px;
      }
      td {
        width: 150px;
        height: 30px;
      }
    }
    #tableBox {
      display: flex;
      justify-content: space-between;
    }
    #today-lesson {
      h3 {
        margin-bottom: 20px;
      }
      margin-left: 80px;
    }
    button {
      margin: 0 10px;
      width: 40px;
    }
    input {
      line-height: 1.5;
      outline: none;
      margin: 0 5px;
    }
  }
`;

const MemberForm = () => {
  // 로컬 스토리지에서 데이터 가져오기
  const localID = localStorage.getItem("id");
  const [visible, setVisible] = useState(false);
  const [selectedStudentName, setSelectedStudentName] = useState("");
  const [todaysLessons, setTodaysLessons] = useState([]);
  const [formData, setFormData] = useState({
    userId: localID,
    name: "",
    phone: "",
    lesson: "",
  });

  const [memberData, setMemberData] = useState([]);
  const [UserID, setUserId] = useState(localID);

  //오늘 수업한 학생 불러오기
  const fetchTodaysLessons = async () => {
    try {
      const today = new Date().toISOString().split("T")[0]; // 오늘의 날짜를 YYYY-MM-DD 형식으로 가져옴
      const response = await axios.get(
        `https://${ip}/api/lessonList/today?date=${today}&userId=${UserID}`
      );
      setTodaysLessons(response.data);
      // 여기서 response.data를 사용하여 오늘 수업한 학생 리스트를 처리합니다.
    } catch (error) {
      console.error("Error fetching today's lessons:", error);
    }
  };
  //멤버리스트 모두 불러오기
  const fetchMemberData = async () => {
    setUserId(localID);
    try {
      const response = await axios.get(
        `https://${ip}/api/member?userId=${UserID}`
      );
      setMemberData(response.data.sort((a, b) => (a.name > b.name ? 1 : -1)));
    } catch (error) {
      console.error("Error fetching member data:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setUserId(localID);
    //학원생 새로 추가하기
    try {
      const response = await axios.post(`https://${ip}/api/member/`, formData);
      console.log(response.data.message);
      fetchMemberData(); // 저장 후 멤버 데이터 다시 불러오기
      alert(`${formData.name} 님의 등록을 축하드립니다.`);
    } catch (error) {
      console.error("Error:", error.response.data.error);
    }
  };
  //그만둔 학원생 삭제하기
  const handleDeleteButtonClick = async (index) => {
    const memberName = memberData[index].name; // 해당 멤버의 이름으로 찾기
    try {
      const response = await axios.delete(
        `https://${ip}/api/member/${memberName}?userId=${UserID}`
      );
      console.log(response.data.message);
      alert(`${memberName} 님이 삭제되었습니다.`);
      fetchMemberData(); // 갱신된 멤버 데이터 다시 불러오기
    } catch (error) {
      console.error("Error:", error.response.data.error);
    }
  };
  //수업 취소 레슨 횟수 +1
  const handleCancelLessonButtonClick = async (index) => {
    const memberName = memberData[index].name; // 해당 멤버의 아이디
    try {
      const response = await axios.put(
        `https://${ip}/api/member/cancel/${memberName}?userId=${UserID}`
      );
      console.log(response.data.message);
      fetchMemberData(); // 갱신된 멤버 데이터 다시 불러오기
    } catch (error) {
      console.error("Error:", error.response.data.error);
    }
    // 수업 취소 레슨 리스트에서도 삭제하기
    try {
      const response = await axios.delete(
        `https://${ip}/api/lessonList/${memberName}?userId=${UserID}`
      );
      console.log(response.data.message);
      console.log(response.data);
      fetchMemberData(); // 갱신된 멤버 데이터 다시 불러오기
    } catch (error) {
      console.error("Error:", error.response.data.error);
    }
  };
  const handleLessonButtonClick = async (index) => {
    const memberName = memberData[index].name; // 해당 멤버의 아이디
    const updatedLesson = parseInt(memberData[index].lesson) - 1; // 현재 레슨에서 1을 뺀 값
    try {
      //수업 후 레슨 수 -1
      const response = await axios.put(
        `https://${ip}/api/member/${memberName}?userId=${UserID}`,
        {
          lesson: updatedLesson,
        }
      );
      //수업 후 레슨 리스트에도 추가해주기
      await axios.post(`https://${ip}/api/lessonList`, {
        userId: UserID,
        name: memberName,
        phone: memberData[index].phone,
      });

      console.log("Lesson button clicked successfully.");
      alert(`${memberName} 님 수업 1회 진행, 수고하셨습니다!`);
      fetchMemberData(); // 갱신된 멤버 데이터 다시 불러오기
      fetchTodaysLessons();
    } catch (error) {
      console.error("Error:", error.response.data.error);
    }
  };

  const handlePayButtonClick = (name) => {
    setSelectedStudentName(name);
    setVisible(true);
  };
  //레슨 결제 레슨 충전하기
  const handleLessonCharge = async (name, lessonCount, userId) => {
    try {
      const response = await axios.put(
        `https://${ip}/api/member/charge/${name}?userId=${userId}`,
        {
          lesson: lessonCount,
        }
      );
      console.log(response.data.message);

      fetchMemberData(); // 갱신된 멤버 데이터 다시 불러오기
    } catch (error) {
      console.error("오류:", error.response.data.error);
    }
  };
  useEffect(() => {
    fetchMemberData();
    fetchTodaysLessons();
  }, []);

  return (
    <Div>
      <h2>새 학원생 정보 입력</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="이름"
          value={formData.name}
          onChange={handleChange}
        />
        <input
          type="text"
          name="phone"
          placeholder="전화번호"
          value={formData.phone}
          onChange={handleChange}
        />
        <input
          type="text"
          name="lesson"
          placeholder="보유 레슨"
          value={formData.lesson}
          onChange={handleChange}
        />

        <button type="submit">저장</button>
      </form>
      <div>
        <div id="tableBox">
          <div id="member">
            <h2>학원생 목록</h2>
            <div>{memberData.length} 명</div>
            <table>
              <thead>
                <tr>
                  <th>이름</th>
                  <th>연락처</th>
                  <th>보유레슨</th>
                  <th>수업</th>
                  <th>결제 확인</th>
                  <th>학원생 삭제</th>
                </tr>
              </thead>
              <tbody>
                {memberData.map((member, index) => (
                  <tr key={index}>
                    <td>
                      <Link to={`/memberdetail/${member.name}`}>
                        {member.name}
                      </Link>
                    </td>
                    <td>{member.phone}</td>
                    <td>{member.lesson}</td>
                    <td>
                      <button onClick={() => handleLessonButtonClick(index)}>
                        수업
                      </button>
                      <button
                        onClick={() => handleCancelLessonButtonClick(index)}
                      >
                        취소
                      </button>
                    </td>
                    <td>
                      <button onClick={() => handlePayButtonClick(member.name)}>
                        결제 완료
                      </button>
                    </td>
                    <td>
                      <button onClick={() => handleDeleteButtonClick(index)}>
                        삭제
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div id="today-lesson">
            <h3>오늘 레슨 내역</h3>
            <ul>
              {todaysLessons.map((student, index) => (
                <li key={index}>{student.name}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <Modal
        handleLessonCharge={handleLessonCharge}
        selectedStudentName={selectedStudentName}
        visible={visible}
        setVisible={setVisible}
      />
    </Div>
  );
};

export default MemberForm;
