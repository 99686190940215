import React from "react";
import Wrapper from "../common/Wrapper";
import Header from "../common/Header";
import Empty from "../common/Empty";
import MemberForm from "../manage/MemberForm";

const ManageMemberPage = () => {
  return (
    <Wrapper>
      <Header />
      <Empty />
      <MemberForm />
      <Empty />
    </Wrapper>
  );
};

export default ManageMemberPage;
