import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { ip } from "../login/LoginForm";

const Div = styled.div`
  /* 미디어 쿼리: 화면 가로폭이 768px 미만일 때 */
  @media screen and (max-width: 768px) {
    width: 100vw;
    font-size: 12px;
  }

  /* 미디어 쿼리: 화면 가로폭이 768px 이상일 때 */
  @media screen and (min-width: 768px) {
    width: 80%;
  }
  .scrollable-table {
    display: block;
    max-height: 600px;
    overflow-y: auto;
    border-collapse: collapse;
  }
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  #box1 {
    display: flex;
    justify-content: space-between;
    table,
    tr,
    td,
    th {
      border: 1px solid #ccc;
      border-collapse: collapse;
    }
    td,
    th {
      width: 150px;
      height: 30px;
      text-align: center;
    }
  }
  #box2 {
    margin-top: 3rem;
    display: flex;
    justify-content: row;
    justify-content: space-evenly;
    table,
    tr,
    td,
    th {
      border: 1px solid #ccc;
      border-collapse: collapse;
    }
    text-align: center;
    line-height: 2;
    .nametable {
      width: 80px;
    }
    .datetable {
      width: 250px;
    }
    .paytable {
      width: 150px;
    }
  }
`;

const LessonForm = () => {
  const localID = localStorage.getItem("id");
  const [userId, setUserId] = useState(localID); // 로컬 스토리지에서 userId 가져오기
  const [lessonList, setLessonList] = useState([]);
  const [payList, setPayList] = useState([]);
  const [thisMonthRevenue, setThisMonthRevenue] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);

  useEffect(() => {
    fetchLessonList();
    fetchPayList();
  }, [selectedMonth, userId]); // 선택한 월과 사용자 ID가 변경될 때마다 데이터를 가져옴

  useEffect(() => {
    fetchLessonList();
    fetchPayList();
  }, [userId]); // 사용자 ID가 변경될 때마다 데이터를 가져옴

  const fetchLessonList = async () => {
    try {
      const response = await axios.get(`https://${ip}/api/lessonListmonth`, {
        params: { month: selectedMonth, userId },
      });
      const formattedLessonList = response.data.map((lesson) => ({
        ...lesson,
        lessonDay: new Date(lesson.lessonDay).toLocaleString("ko-KR", {
          timeZone: "Asia/Seoul",
        }),
      }));
      setLessonList(formattedLessonList);
    } catch (error) {
      console.error("Error fetching lesson list:", error);
    }
  };

  const fetchPayList = async () => {
    try {
      const response = await axios.get(`https://${ip}/api/payListmonth`, {
        params: { month: selectedMonth, userId },
      });
      const formattedPayList = response.data.map((pay) => ({
        ...pay,
        payDay: new Date(pay.payDay).toLocaleString("ko-KR", {
          timeZone: "Asia/Seoul",
        }),
      }));
      setPayList(formattedPayList);
    } catch (error) {
      console.error("Error fetching pay list:", error);
    }
  };

  useEffect(() => {
    let totalRevenue = 0;
    for (let i = 0; i < payList.length; i++) {
      totalRevenue += payList[i].pay;
    }
    setThisMonthRevenue(totalRevenue);
  }, [payList]);

  return (
    <Div>
      <div id="box1">
        <div id="month-select">
          <h3>월 선택</h3>
          <select
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
          >
            <option value="1">1월</option>
            <option value="2">2월</option>
            <option value="3">3월</option>
            <option value="4">4월</option>
            <option value="5">5월</option>
            <option value="6">6월</option>
            <option value="7">7월</option>
            <option value="8">8월</option>
            <option value="9">9월</option>
            <option value="10">10월</option>
            <option value="11">11월</option>
            <option value="12">12월</option>
          </select>
        </div>
        <table id="profit">
          <thead>
            <tr>
              <th>이번달 매출</th>
              <td>{thisMonthRevenue}</td>
            </tr>
            <tr>
              <th>지난달 매출</th>
              <td>지난달 매출</td>
            </tr>
          </thead>
        </table>
      </div>
      <div id="box2">
        <div id="lesson">
          <h3>{selectedMonth}월 수업 현황</h3>
          <table className="scrollable-table">
            <thead>
              <tr>
                <th className="nametable">이름</th>
                <th className="datetable">날짜/시간</th>
              </tr>
            </thead>
            <tbody>
              {lessonList.map((lesson, index) => (
                <tr key={index}>
                  <td>{lesson.name}</td>
                  <td>{lesson.lessonDay}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div id="pay">
          <h3>{selectedMonth}월 결제 현황</h3>
          <table className="scrollable-table">
            <thead>
              <tr>
                <th className="nametable">이름</th>
                <th className="datetable">결제일</th>
                <th className="paytable">결제 금액</th>
              </tr>
            </thead>
            <tbody>
              {payList.map((payment, index) => (
                <tr key={index}>
                  <td>{payment.name}</td>
                  <td>{payment.payDay}</td>
                  <td>{payment.pay}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Div>
  );
};

export default LessonForm;
